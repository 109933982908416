import dayjs from "dayjs"
import { LayoutType } from "@tential/ec-gql-schema/master/pageParts"
import type { RecommendProduct } from "~/types/server/api/recommend/side_cart"

type SizeRecommendEventArg = {
  product_id: string
  gender: string
  height: number
  weight: number
  recommend_size: string
}

export const BannerType = {
  ...LayoutType,
  top_page: "TOPページ",
} as const

export const useGtagEvent = () => {
  const gtag = useGtag()
  const config = useRuntimeConfig()
  const route = useRoute()
  const isProd = config.public.APP_ENV === "production"

  const trackBannerClick = (parts: keyof typeof BannerType, uniqKey: string, index: number, alt: string): void => {
    if (!isProd) return
    const partsName = BannerType[parts]

    const bannerId = alt || `${parts}-${uniqKey}`
    gtag("event", "banner_click", {
      banner_id: bannerId,
      banner_index: index,
      parts_name: partsName,
      timestamp: dayjs().valueOf(),
      path: route.fullPath,
    })
  }

  const trackRecommendProductClick = (product: RecommendProduct) => {
    if (!isProd) return
    gtag("event", "click_recommend_item", {
      recommend_id: product.recommend_id,
      display_type: "sidecart",
      product_id: product.document_id,
    })
  }

  const trackRecommendProductView = (product: RecommendProduct) => {
    if (!isProd) return
    gtag("event", "inview_recommend_item", {
      recommend_id: product.recommend_id,
      display_type: "sidecart",
      product_id: product.document_id,
    })
  }

  const trackSizeRecommendClick = ({ product_id, gender, height, weight, recommend_size }: SizeRecommendEventArg) => {
    if (!isProd) return
    gtag("event", "click_size_recommend", {
      product_id,
      gender,
      height,
      weight,
      recommend_size,
    })
  }

  const trackCategoryClick = (category: string) => {
    if (!isProd) return
    gtag("event", "click_category", {
      category,
      path: route.fullPath,
    })
  }

  const trackProductListItemClick = (product_id: string) => {
    if (!isProd) return
    gtag("event", "click_product_list_item", {
      product_id,
      path: route.fullPath,
    })
  }

  return {
    trackBannerClick,
    trackRecommendProductClick,
    trackRecommendProductView,
    trackSizeRecommendClick,
    trackCategoryClick,
    trackProductListItemClick,
  }
}
